import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Card from "../components/card"
import Nodino from "../components/nodino"
import BlueSpan from "../components/blueSpan"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnchor } from "@fortawesome/free-solid-svg-icons"


const nodi = [
  {
    title: "Nodo Semplice",
    filename: "nodi-marinari-semplici.jpg",
    link: "nodi-marinari-di-arresto",
  },
  {
    title: "Nodo Savoia",
    filename: "nodi-marinari-savoia.jpg",
    link: "nodi-marinari-di-arresto",
  },
  {
    title: "Nodo del Cappuccino",
    filename: "nodi-marinari-del-cappuccino.jpg",
    link: "nodi-marinari-di-arresto",
  },
  {
    title: "Nodo del Francescano",
    filename: "nodi-marinari-del-francescano.jpg",
    link: "nodi-marinari-di-arresto",
  },
  {
    title: "Nodo Piano",
    filename: "nodi-marinari-piano.jpg",
    link: "nodi-marinari-di-giunzione",
  },
  {
    title: "Nodo Piano Ganciato",
    filename: "nodi-marinari-piano-ganciato.jpg",
    link: "nodi-marinari-di-giunzione",
  },
  {
    title: "Nodo Bandiera",
    filename: "nodi-marinari-bandiera.jpg",
    link: "nodi-marinari-di-giunzione",
  },
  {
    title: "Nodo Bandiera Doppio",
    filename: "nodi-marinari-bandiera-doppio.jpg",
    link: "nodi-marinari-di-giunzione",
  },
  {
    title: "Nodo Bandiera Ganciato",
    filename: "nodi-marinari-bandiera-ganciato.jpg",
    link: "nodi-marinari-di-giunzione",
  },
  {
    title: "Nodo Vaccaio",
    filename: "nodi-marinari-vaccaio.jpg",
    link: "nodi-marinari-di-giunzione",
  },
  {
    title: "Nodo Inglese",
    filename: "nodi-marinari-inglese.jpg",
    link: "nodi-marinari-di-giunzione",
  },
  {
    title: "Nodo Inglese Doppio",
    filename: "nodi-marinari-inglese-doppio.jpg",
    link: "nodi-marinari-di-giunzione",
  },
  {
    title: "Nodo del Chirurgo",
    filename: "nodi-marinari-del-chirurgo.jpg",
    link: "nodi-marinari-di-giunzione",
  },
  {
    title: "Nodo di Rosetta",
    filename: "nodi-marinari-di-rosetta.jpg",
    link: "nodi-marinari-di-giunzione",
  },
  {
    title: "Nodo Due Gasse",
    filename: "nodi-marinari-due-gasse.jpg",
    link: "nodi-marinari-di-giunzione",
  },
  {
    title: "Nodo Parlato Semplice",
    filename: "nodi-marinari-parlato-semplice.jpg",
    link: "nodi-marinari-di-avvolgimento",
  },
  {
    title: "Nodo Collo Scorrevole",
    filename: "nodi-marinari-collo-scorrevole.jpg",
    link: "nodi-marinari-di-avvolgimento",
  },
  {
    title: "Nodo Bocca Di Lupo",
    filename: "nodi-marinari-bocca-di-lupo.jpg",
    link: "nodi-marinari-di-avvolgimento",
  },
  {
    title: "Nodo di Coltellaccio",
    filename: "nodi-marinari-di-coltellaccio.jpg",
    link: "nodi-marinari-di-avvolgimento",
  },
  {
    title: "Nodo di Coltellaccio Doppio",
    filename: "nodi-marinari-di-coltellaccio-doppio.jpg",
    link: "nodi-marinari-di-avvolgimento",
  },
  {
    title: "Nodo di Parlato Doppio",
    filename: "nodi-marinari-parlato-doppio.jpg",
    link: "nodi-marinari-di-avvolgimento",
  },
  {
    title: "Nodo due volte e due mezzi colli",
    filename: "nodi-marinari-due-volte-e-due-mezzi-colli.jpg",
    link: "nodi-marinari-di-avvolgimento",
  },
  {
    title: "Nodo di giara",
    filename: "nodi-marinari-di-giara.jpg",
    link: "nodi-marinari-di-avvolgimento",
  },
  {
    title: "Nodo margherita",
    filename: "nodi-marinari-margherita.jpg",
    link: "nodi-marinari-di-accorciamento-e-regolazione",
  },
  {
    title: "Nodo semplice con gassa",
    filename: "nodi-marinari-semplice-con-gassa.jpg",
    link: "nodi-marinari-di-accorciamento-e-regolazione",
  },
  {
    title: "Nodo per manette",
    filename: "nodi-marinari-per-manette.jpg",
    link: "nodi-marinari-di-accorciamento-e-regolazione",
  },
  {
    title: "Gassa D'amante",
    filename: "nodi-marinari-gassa-di-amante.jpg",
    link: "nodi-marinari-a-occhio",
  },
  {
    title: "Gassa Spagnola",
    filename: "nodi-marinari-gassa-spagnola.jpg",
    link: "nodi-marinari-a-occhio",
  },
  {
    title: "Gassa d'amante doppia senza cima",
    filename: "nodi-marinari-gassa-di-amante-doppia-senza-cima.jpg",
    link: "nodi-marinari-a-occhio",
  },
  {
    title: "Gassa Tripla o Incappellaggio",
    filename: "nodi-marinari-gassa-tripla-o-incappellaggio.jpg",
    link: "nodi-marinari-a-occhio",
  },
]

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="container mx-auto">
      <div className="font-serif shadow p-5 text-justify italic text-xl mt-5">
        I <BlueSpan>nodi marinari</BlueSpan> caratterizzano da secoli una
        peculiarità di tutto ciò che è legato al mare. Sono di fondamentale
        importanza per chi va in mare e non esiste marinaio che non conosca i
        nodi marinari di base.
        <br />
        Anche se non sei un marinaio esperto, se possiedi un imbarcazione, è
        importante che tu conosca almeno i{" "}
        <BlueSpan>nodi marinari di base</BlueSpan>.
        <br />
        Inoltre, per i più riflessivi, ogni nodo può avere un significato
        particolare e sono stati tanti i filosofi e scrittori che hanno scritto
        e dedicato qualcosa inerente i nodi marinari.
        <br />
        Oggi, i nodi marinari, vengono utilizzati in qualsiasi ambito e per i
        più svariati fini, tale è la loro duttilità e versatilità. <br /> Una
        persona che conosce come realizzare i nodi marinari sa beninissimo che
        esiste <BlueSpan>un nodo per ogni occasione</BlueSpan>.
        <br />
        Su <BlueSpan>nodimarinari.com</BlueSpan>, vi offriamo una panoramica di
        tutti i nodi marinari esistenti e quadri di nodi marinari ornamentali
        realizzati a mano.
        <br></br>Buona visita
      </div>
      <Card className="mt-5"></Card>

      <h1 className="text-center text-blue-500 font-bold text-3xl mt-12">
        <FontAwesomeIcon
          className="text-blue-600 stroke-current text-black"
          size="lg"
          icon={faAnchor}
        />{" "}
        I nodi marinari{" "}
        <FontAwesomeIcon
          className="text-blue-600 stroke-current text-black"
          size="lg"
          icon={faAnchor}
        />
      </h1>

      <div className="flex flex-wrap justify-center  mt-5">
        {" "}
        {nodi.map(nodo => (
          <Nodino
            className="w-full sm:w-1/4 md:w-1/4 lg:w-1/6"
            filename={nodo.filename}
            nomeNodo={nodo.title}
            link={nodo.link}
          ></Nodino>
        ))}
      </div>
    </div>
  </Layout>
)

export default IndexPage
