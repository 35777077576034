import React from "react"
import { Link } from "gatsby"
import Image from "./image"

const Nodino = props => (
  <Link
    to={props.link}
    className={`${props.className} bg-blue-100 flex flex-col shadow rounded-lg p-2 m-2 hover:bg-blue-200 `}
  >
    <Image className="flex-2 rounded-lg" filename={props.filename} alt={props.nomeNodo}></Image>
    <div className="flex-1 m-5">
      <h2 className="text-blue md:text-md lg:text-lg font-bold text-center">
        {props.nomeNodo}
      </h2>
    </div>
  </Link>
)

export default Nodino